// React Core
import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

// Notifiche
import {SnackbarProvider} from 'notistack';

// Redux Storage
import {connect} from 'react-redux';
import store from './utils/store';

// Material-UI
import {ThemeProvider, createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';

// Sub-Components
import Page404 from './stateless/404';
import SideNav from "./components/SideNav";

const Home = lazy(() => import('./home'));
const Login = lazy(() => import('./components/Login'));
const Dashboard = lazy(() => import('./modules/dashboard'));
const Report = lazy(() => import('./modules/report'));
const Settings = lazy(() => import('./modules/settings'));

//const Admin = lazy(() => import('./admin/admin'));

interface AppProps {
    darkTheme: boolean
}

class App extends React.Component<AppProps> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            ...store.getState()
        };
    }


    render() {
        let theme = createMuiTheme({
            palette: {
                primary: {main: '#04AA6D'},
                type: this.props.darkTheme ? 'dark' : 'light'
            }
        });
        theme = responsiveFontSizes(theme);

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <SnackbarProvider maxSnack={5}>
                        <Router>
                            <Route>
                                <Box>
                                    <Grid container
                                          spacing={0}
                                          alignItems="center"
                                          justify="center">
                                        <Grid item xs={12}>
                                            <SideNav reload={() => {
                                            }} direction={'rtl'}/>
                                        </Grid>
                                        <Grid item xs={12} style={{margin: 70}}>
                                            <Grid container
                                                  spacing={0}
                                                  alignItems="center"
                                                  justify="center">
                                                <Grid item xs={11}>
                                                    <Suspense fallback={<CircularProgress/>}>
                                                        <Switch>
                                                            <Route exact path="/" component={Home}/>
                                                            <Route exact path="/login" component={Login}/>
                                                            <Route exact path="/dashboard/:ambiente"
                                                                   component={Dashboard}/>
                                                            <Route exact path="/report" component={Report}/>
                                                            <Route exact path="/settings" component={Settings}/>
                                                            <Route component={Page404}/>
                                                        </Switch>
                                                    </Suspense>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Route>
                        </Router>
                    </SnackbarProvider>
                </CssBaseline>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (store: any) => {
    return {
        darkTheme: store.darkTheme
    };
};

export default connect(mapStateToProps)(App);
