import {Theme} from '@material-ui/core/styles';
import {createStyles} from '@material-ui/core';

export type StyleType = {
    root: string
    appBar: string,
    appBarShift: string,
    menuButton: string,
    hide: string,
    drawer: string,
    drawerOpen: string,
    drawerClose: string,
    toolbar: string,
    content: string,
    chartAnalytics: string,
    Line: string,
    fastSearch: string,
    ordered: string,
    flag: string,
    card: string,
    cardSettings: string,
    CircularProgress: string,
    DonutHome: string,
    pos: string,
    table: string,
    tableCell: string,
    wave: string,
    projectNotUpdated: string,
    projectUpdated: string,
    flagMini: string,
    flagButton: string,
    Paper: string,
    textField: string,
    Tabs: string,
    hellipsis: string,
    w100: string
};

const drawerWidth = 240;

export const styles = (palette: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        //position: 'relative',
        zIndex: palette.zIndex.drawer + 1,
        transition: palette.transitions.create(['width', 'margin'], {
            easing: palette.transitions.easing.sharp,
            duration: palette.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: palette.transitions.create(['width', 'margin'], {
            easing: palette.transitions.easing.sharp,
            duration: palette.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: palette.transitions.create('width', {
            easing: palette.transitions.easing.sharp,
            duration: palette.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: palette.transitions.create('width', {
            easing: palette.transitions.easing.sharp,
            duration: palette.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: palette.spacing(7) + 1,
        [palette.breakpoints.up('sm')]: {
            width: palette.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: palette.spacing(0, 1),
        // necessary for content to be below app bar
        ...palette.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: palette.spacing(3),
        width: '100%'
    },
    chartAnalytics: {
        marginLeft: -80,
        height: 300,
        overflow: 'hidden',
    },

    Line: {
        width: '100%',
    },

    fastSearch: {
        color: 'white',
        fontSize: '1.25em'
    },

    ordered: {
        backgroundColor: '#d3d3d338'
    },

    flag: {
        width: 50,
        //float: 'right',
        borderRadius: '10px',
        boxShadow: '-5px 0px 5px 0px #343434',
        outline: '2px solid rgb(97 97 97 / 90%)',
        outlineOffset: '-1px'
    },

    flagMini: {
        width: 30,
        marginLeft: 2,
        borderRadius: '50px'
    },

    cardSettings: {
        height: '100%',
        '& canvas': {
            margin: '0 auto'
        }
    },

    card: {
        zIndex: 1,
        position: 'relative',
        '& canvas': {
            margin: '0 auto'
        }
    },


    CircularProgress: {
        display: 'block',
        margin: '0 auto',
        textAlign: 'center'
    },

    DonutHome: {
        '& canvas': {
            position: 'absolute',
            /*maxWidth: 200,
            right: -40,
            bottom: -40,
            '&:after': {
                content: 'Sessions'
            }*/
        },


    },
    pos: {},
    table: {},
    tableCell: {},

    projectNotUpdated : {
        background: '#ffa59e',
    },
    projectUpdated: {
        background: '#bce7c5',
    },

    wave: {
        position: 'relative',
        height: '17px',
        width: '100%',
        //background: '#e0efe3',
        '& div:nth-child(1)': {
            top: '0px',
            left: '-15px',
            width: '105%',
            height: '39px',
            content: '',
            display: 'block',
            position: 'absolute',
            borderRadius: '100% 50%',
            backgroundColor: palette.palette.background.paper
        }
    },

    flagButton:{
        float: 'right'
    },

    Paper:{
        marginTop: 10,
        marginBottom: 10,
        padding: 5
    },

    textField: {
        width: '100%',
    },

    Tabs:{
        backgroundColor: palette.palette.primary.main,
        color: 'white'
    },

    hellipsis: {
        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
    },

    w100: {
        width: '100%'
    }
});
