import store from './store';

export function stateAuthToProps () {
    const state = store.getState();

    return {
        auth: state.auth
    };
}

export interface stateAuthProps {
    auth: boolean | undefined
}

export function stateThemeToProps () {
    const state = store.getState();

    return {
        darkTheme: state.darkTheme
    };
}

export interface stateThemeProps {
    darkTheme: boolean | undefined
}

export function stateRangeToProps () {
    const state = store.getState();

    return {
        start: state.filter.start,
        end: state.filter.end,
        start_prev: state.filter.start_prev,
        end_prev: state.filter.end_prev,
        range: state.filter.range
    };
}

export interface stateRangeProps {
    start: string,
    end: string,
    start_prev: string,
    end_prev: string,
    range: string
}

export function stateProjectsToProps () {
    const state = store.getState();

    return {
        projects: state.projects
    };
}

export interface stateProjectsProps {
    projects: any
}

export function stateCurrentProjectToProps () {
    const state = store.getState();

    return {
        project_id: state.current_project.id,
        project_name: state.current_project.name,
        date_baseline: state.current_project.date_baseline,
        date_extraction: state.current_project.date_extraction,
        project_targets: state.current_project.targets,
        current_project: state.current_project,
        current_target: state.current_project.current_target,
        auth: state.auth
    };
}

export interface stateCurrentProjectProps {
    project_id: number,
    project_name: string,
    date_baseline: string,
    date_extraction: string,
    project_targets: any,
    current_project: any,
    current_target: string,
    auth: boolean | undefined


}
