// React Core
import React from 'react';

// Notifiche
import {ProviderContext, withSnackbar} from 'notistack';

// History
import {RouteComponentProps, withRouter} from 'react-router-dom';

// Redux Storage
import {connect} from 'react-redux';
import store from '../utils/store';

// Material-UI
import {StyledComponentProps, withStyles} from '@material-ui/core';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InputBase from '@material-ui/core/InputBase';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';

// Internal Graphics
import {styles} from '../utils/styles';
import Grid from "@material-ui/core/Grid";
import Periodo from "./Periodo";

// SETA
import {setSeta} from '../utils/seta';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import {
    stateAuthProps,
    stateAuthToProps, stateCurrentProjectProps,
    stateCurrentProjectToProps,
    stateProjectsProps,
    stateProjectsToProps,
    stateThemeProps,
    stateThemeToProps
} from "../utils/props";

interface SidebarProps extends RouteComponentProps, StyledComponentProps, ProviderContext, stateThemeProps, stateAuthProps, stateProjectsProps, stateCurrentProjectProps {
    reload: () => any,
    direction: string,
    classes: any
}

interface SidebarState {
    open: boolean
}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
    constructor(props: SidebarProps) {
        super(props);

        this.state = {
            open: false,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleDark = this.toggleDark.bind(this);
        this.changeProject = this.changeProject.bind(this);

    }

    toggleModal() {
        this.setState(prevState => {
            return ({
                ...prevState,
                open: !this.state.open
            });
        });
    }

    toggleDark() {
        // this.setState(prevState => {
        //     return ({
        //         ...prevState,
        //         darkTheme: !this.state.darkTheme
        //     });
        // });
        store.dispatch({type: 'darkTheme', darkTheme: !this.props.darkTheme});
    }

    logout() {
        setSeta('logout').then((res: any) => {
            if (!res.auth) {
                if (this.props.reload !== undefined)
                    this.props.reload();
                else
                    this.props.history.push("/");
            }
        }).catch(rej => {
            if (rej !== undefined) {
                this.props.enqueueSnackbar(rej, {variant: 'success', autoHideDuration: 3000});
                if (this.props.reload !== undefined)
                    this.props.reload();
                else
                    this.props.history.push("/");
            }
        });
    }

    changeProject(event: any, values: any) {
        if (values) {
            const project = this.props.projects.filter((project:any) => project.id === values.id)[0];
            store.dispatch({type: 'current_project', current_project: project});
            this.props.history.push('/dashboard/' + project.name);
        }

    };

    render() {
        const classes = this.props.classes;

        if (this.props.auth === false) {
            return (<></>);
        }

        return (
            <div>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.toggleModal}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: this.state.open,
                            })}
                        >
                            <MenuIcon/>
                        </IconButton>

                        {this.props.project_id && this.props.history.location.pathname.includes('dashboard') &&
                        <Grid container direction={'row'}>

                            <Grid container xs={5} sm={5} md={6} lg={9} style={{margin: "auto"}}>
                                <Typography variant="h6" noWrap>dismos\</Typography>
                                <Grid item lg={3}>
                                    <Autocomplete
                                        id="fastSearch"
                                        options={this.props.projects}
                                        getOptionLabel={(option) => option.name}
                                        onChange={this.changeProject}
                                        placeholder={this.props.project_name}
                                        style={{width: 300, padding: 0, margin: 0}}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <InputBase
                                                    {...params}
                                                    className={`${classes.input} ${classes.fastSearch}`}
                                                    style={{paddingTop: 0}}
                                                    placeholder={this.props.project_name}
                                                />
                                            </div>)}

                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={7} sm={7} md={6} lg={3}>
                                <Periodo />
                            </Grid>
                        </Grid>
                        }

                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.toggleModal}>
                            {this.props.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>
                        {['Home'].map((text, index) => (
                            <ListItem
                                button key={text}
                                onClick={() => {
                                    this.props.history.push('/');
                                }}
                            >
                                <ListItemIcon><HomeIcon/></ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItem>
                        ))}
                    </List>
                    <List>
                        {['Report'].map((text, index) => (
                            <ListItem
                                button key={text}
                                onClick={() => {
                                    this.props.history.push('/report');
                                }}
                            >
                                <ListItemIcon><ListAltIcon/></ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItem>
                        ))}
                    </List>
                    <List>
                        {['Settings'].map((text, index) => (
                            <ListItem
                                button key={text}
                                onClick={() => {
                                    this.props.history.push('/settings');
                                }}
                            >
                                <ListItemIcon><SettingsIcon/></ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <List>
                        {['Logout'].map((text, index) => (
                            <ListItem button key={text} onClick={() => {
                                this.logout();
                            }}>
                                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItem>
                        ))}
                    </List>
                    <Tooltip title="Dark Mode" aria-label="darkmode">
                        <Switch
                            checked={this.props.darkTheme}
                            onChange={this.toggleDark}
                            name="darkTheme"
                            color="primary"
                            inputProps={{'aria-label': 'Dark Mode'}}
                        />
                    </Tooltip>
                </Drawer>
            </div>
        );
    }

}

function mapStateToProps() {
    return {
        ...stateThemeToProps(),
        ...stateAuthToProps(),
        ...stateProjectsToProps(),
        ...stateCurrentProjectToProps()
    };
}

export default connect(mapStateToProps)(withRouter(withSnackbar(withStyles(styles)(Sidebar))));
