// React Core
import React from 'react';

// History
import { RouteComponentProps } from 'react-router';

// Material-UI
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const styles = () => createStyles({
    root: {
        display: 'flex',
    }
});

interface Page404Props extends RouteComponentProps, WithStyles<typeof styles> {}

class Page404 extends React.Component<Page404Props> {
    render () {
        const { classes } = this.props;

        return (
            <Box className={classes.root}>
                <Grid container spacing={0} justify='center'>
                    <Grid item xs={12} onClick={() => this.props.history.go(-1)} className='div-back'>
                        Torna indietro
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default withStyles(styles)(Page404);
