import { createStore } from 'redux';
import dismosReducer from './reducer';

import { ReducerStateType } from './reducer.d';

const storeName: string = 'dismos_state';

const loadState = () => {
    const serializedState: string | null = localStorage.getItem(storeName);
    if (serializedState) {
        return JSON.parse(serializedState);
    }
};

const saveState = (state: ReducerStateType) => {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(storeName, serializedState);
};

let persistedState: ReducerStateType = loadState();

if (persistedState === null) {
    saveState({
        ...dismosReducer()
    });
    persistedState = loadState();
}

const store = createStore(dismosReducer, persistedState);

store.subscribe(() => {
    saveState({
        ...store.getState()
    });
});

export default store;
