// React Core
import React from 'react';

// Notifiche
import {ProviderContext, withSnackbar} from 'notistack';

// History
import { withRouter } from 'react-router-dom';

// Redux Storage
import { connect } from 'react-redux';
import store from '../utils/store';

// Material-UI
import {StyledComponentProps, withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material-UI Icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Sub-Components
import {DateRangePicker} from 'materialui-daterange-picker';
import moment from 'moment';
import 'moment-timezone';

// Internal Graphics
import {styles, StyleType} from "../utils/styles";

import {stateCurrentProjectProps, stateCurrentProjectToProps, stateRangeProps, stateRangeToProps} from '../utils/props';
import {RouteComponentProps} from "react-router";

export interface PeriodoProps extends RouteComponentProps, StyledComponentProps, ProviderContext, stateRangeProps, stateCurrentProjectProps {
    classes: StyleType
}

export interface PeriodoState {
    calendar: boolean,
    ranges: any
}

class Periodo extends React.Component<PeriodoProps, PeriodoState> {
    constructor (props: PeriodoProps) {
        super(props);

        this.state = {
            calendar: false,

            ranges: [
                {
                    startDate: moment().startOf('month').format('YYYY/MM/DD'),
                    endDate: moment().format('YYYY/MM/DD'),
                    label: 'Mese corrente'
                },
                {
                    startDate: moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD'),
                    endDate: moment().subtract(1, 'month').endOf('month').format('YYYY/MM/DD'),
                    label: 'Mese precedente'
                },
                {
                    startDate: moment().subtract(6, 'months').add(1, 'day').format('YYYY/MM/DD'),
                    endDate: moment().format('YYYY/MM/DD'),
                    label: 'Ultimi 6 mesi'
                },
                {
                    startDate: moment().subtract(12, 'months').add(1, 'day').format('YYYY/MM/DD'),
                    endDate: moment().format('YYYY/MM/DD'),
                    label: 'Ultimi 12 mesi'
                },
                {
                    startDate: moment().subtract(18, 'months').add(1, 'day').format('YYYY/MM/DD'),
                    endDate: moment().format('YYYY/MM/DD'),
                    label: 'Ultimi 18 mesi'
                },
                {
                    startDate: moment().subtract(1, 'years').format('YYYY') + '/01/01',
                    endDate: moment().subtract(1, 'years').format('YYYY') + '/12/31',
                    label: 'Ultimo anno'
                },
                {
                    startDate: moment(this.props.date_baseline, 'DD/MM/YYYY').format('YYYY/MM/DD'),
                    endDate: moment(this.props.date_extraction, 'DD/MM/YYYY').format('YYYY/MM/DD'),
                    label: 'Archivio'
                }

            ]
        };

        this.toggleCalendar = this.toggleCalendar.bind(this);
        this.changeCalendar = this.changeCalendar.bind(this);
    }

    /** gestione state **/

    shouldComponentUpdate(nextProps: PeriodoProps, nextState: PeriodoState) {
        return nextProps.start !== this.props.start || nextProps.start_prev !== this.props.start_prev || nextState !== this.state || nextProps.project_id !== this.props.project_id;
    }

    componentDidMount() {
        if (this.props.start === undefined) {
            const temp = this.state.ranges[2]; //ultimi 6 mesi

            store.dispatch({
                type: 'range',
                start: temp.startDate,
                end: temp.endDate,
                start_prev: undefined,
                end_prev: undefined,
                range: temp.label
            });
        }
    }

    /** eventi modulo */

    toggleCalendar() {
        this.setState((prevState: PeriodoState) => {
            return ({
                ...prevState,
                calendar: !this.state.calendar
            });
        });
    }

    changeCalendar(range: any) {
        const label = (range.label) ? range.label.replace(/[^\d]*/g, '') || 'custom' : 'custom';
        const startDate = range.startDate.getFullYear() + '-' + ('0' + (range.startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + range.startDate.getDate()).slice(-2);
        const endDate = range.endDate.getFullYear() + '-' + ('0' + (range.endDate.getMonth() + 1)).slice(-2) + '-' + ('0' + range.endDate.getDate()).slice(-2);
        store.dispatch({
            type: 'range',
            start: startDate,
            end: endDate,
            start_prev: undefined,
            end_prev: undefined,
            range: label
        });
        this.toggleCalendar();
    }

    /** render componente **/

    render() {

        return (
            <Box hidden={this.props.project_id === null}>
                <Grid container>
                    <Box m="auto">
                        <Grid item xs>
                            Range: {this.props.range === 'custom' ? 'Manuale' : this.props.range === 'full' ? 'Intero progetto' : this.props.range + ' mesi'} ({this.props.range && moment(this.props.start).format('DD/MM/YYYY')} - {this.props.range && moment(this.props.end).format('DD/MM/YYYY')})
                            <br/>
                            Periodo di
                            confronto: {this.props.start_prev && this.props.end_prev ? moment(this.props.start_prev).format('DD/MM/YYYY') + ' - ' + moment(this.props.end_prev).format('DD/MM/YYYY') : 'Non definito'}
                        </Grid>
                    </Box>
                    <Grid item>
                        <IconButton onClick={this.toggleCalendar}>
                            <ArrowDropDownIcon onClick={(e) => {
                                this.toggleCalendar()
                            }}/>
                        </IconButton>


                        <Dialog maxWidth="md" open={this.state.calendar}>
                            <DialogTitle id="simple-dialog-title">Select a range</DialogTitle>
                            <DialogContent>
                                <DateRangePicker
                                    open={true}
                                    onChange={(range) => this.changeCalendar(range)}
                                    toggle={this.toggleCalendar}
                                    definedRanges={this.state.ranges}
                                />
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = () => {
    return {
        ...stateCurrentProjectToProps(),
        ...stateRangeToProps()
    };
};

export default connect(mapStateToProps)(withRouter(withSnackbar(withStyles(styles)(Periodo))));
