import axios from 'axios';
import store from './store';
import history from './history';
axios.defaults.timeout = 120000;

export function getSeta (api: string, data: string = '', override: boolean = false) {
    const state = store.getState();
    let string: string;

    if (process.env.NODE_ENV === 'development') {
        string = 'http://localhost:3000/dismos/';
    } else {
        string = 'https://seta.gbsweb.online:3000/dismos/';
    }

    string += api;

    if (state?.current_project?.constructor === Object && Object.keys(state.current_project).length && !override) {
        string += '?id=' + state.current_project.id;
        string += '&target=' + state.current_project.current_target;

        if (state.filter.start !== undefined && state.filter.end !== undefined) {
            string += '&from=' + state.filter.start + '&to=' + state.filter.end;
        }
    }

    if (data !== '') {
        string += data;
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: string,
            withCredentials: true
        }).then(res => {
            if (res.data.auth === false) {
                store.dispatch({ type: 'auth', auth: false });
                reject(res.data.message);
                history.push('/');
                window.location.reload();

            } else if (res.data.status === false) {
                store.dispatch({ type: 'auth', auth: true });
                reject(res.data);
            } else {
                store.dispatch({ type: 'auth', auth: true });
                if (res.data.data.start) {
                    store.dispatch({
                        type: 'range',
                        start: res.data.data.start,
                        end: res.data.data.end,
                        start_prev: res.data.data.start_prev,
                        end_prev: res.data.data.end_prev,
                        range: res.data.data.range
                    });
                }
                resolve(res.data.data);
            }
        }).catch(error => reject(error));
    });
}

export function setSeta (api: string, data: any = null) {
    let string: string;

    if (process.env.NODE_ENV === 'development') {
        string = 'http://localhost:3000/dismos/';
    } else {
        string = 'https://seta.gbsweb.online:3000/dismos/';
    }

    string += api;

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: string,
            withCredentials: true,
            data: {
                ...data
            }
        }).then(res => {
            if (res.data.auth === false) {
                store.dispatch({ type: 'auth', auth: false });
                reject(res.data.message);
                history.push('/');
                window.location.reload();
            } else if (res.data.status === false) {
                reject(res.data);
            } else {
                resolve(res.data);
            }
        }).catch(error => reject(error));
    });
}

export function delSeta (api: string, data: any) {
    let string: string;

    if (process.env.NODE_ENV === 'development') {
        string = 'http://localhost:3000/dismos/';
    } else {
        string = 'https://seta.gbsweb.online:3000/dismos/';
    }

    string += api;

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: string,
            withCredentials: true,
            data: {
                ...data
            }
        }).then(res => {
            if (res.data.auth === false) {
                store.dispatch({ type: 'auth', auth: false });
                reject(res.data.message);
                history.push('/');
                window.location.reload();
            } else if (res.data.status === false) {
                reject(res.data.data);
            } else {
                resolve(res.data.data);
            }
        }).catch(error => reject(error));
    });
}
